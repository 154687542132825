<ng-container *ngIf="gallery | async as gallery">
  <p class="font-weight-bolder text-uppercase horizontal-text mb-1">
    {{gallery.pictures[pictureIndex].directus_files_id.title}}
    <label class="font-weight-normal">{{gallery.name}} {{gallery.month}}  {{gallery.year}}</label>
  </p>
  <div class="mobile-wrapper">
    <swiper
      [slidesPerView]="1"
      [spaceBetween]="0"
      (slideChange)="onSlideChange($event)"
      [autoHeight]="true"
      [loop]="false"
      [pagination]="{ clickable: true, bulletElement: 'bullet' }"
      [initialSlide]="pictureIndex"
      [cssMode]="isChromeForIOs145"
      [watchOverflow]="false"
    >
      <ng-template *ngFor="let pic of gallery.pictures" swiperSlide>
        <img src="http://backend.mirkoreichlin.ch/assets/{{pic.directus_files_id.filename_disk}}?width=1080" class="w-100">
      </ng-template>
    </swiper>
  </div>
</ng-container>

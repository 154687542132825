<div class="container content" *ngIf="gallery | async as gallery">
  <div class="text-center">
    <i class="bi bi-arrow-up-circle-fill h4 pointer" routerLink="/photo"></i>
  </div>

  <swiper
    [slidesPerView]="1"
    [spaceBetween]="0"
    (slideChange)="onSlideChange($event)"
    [autoHeight]="false"
    [loop]="false"
    [pagination]="{ clickable: true, bulletElement: 'bullet' }"
    [initialSlide]="pictureIndex"
    [slideToClickedSlide]="true"
    [cssMode]="isChromeForIOs145"
    class="pointer"
  >
    <ng-template *ngFor="let pic of gallery.pictures" swiperSlide>
      <div class="card m-4 image"
           [class.image-horizontal]="!gallery.vertical"
           [class.image-vertical]="gallery.vertical"
      >
        <div class="card-body h-100">
          <div>
            <div class="d-flex h-100">
              <div class="d-flex flex-column justify-content-between mr-3 info-box-left" *ngIf="gallery.vertical">
                <p class="font-weight-bolder text-uppercase horizontal-text">
                  {{gallery.month}}<br/><label class="font-weight-normal mb-0"> {{gallery.year}}</label>
                </p>
                <p class="font-weight-bolder text-uppercase vertical-text-left">
                  {{pic.directus_files_id.title}}<br><label class="font-weight-normal mb-0">{{gallery.name}}</label>
                </p>
              </div>
              <div class="d-inline-block position-relative h-100">
                <img src="http://backend.mirkoreichlin.ch/assets/{{pic.directus_files_id.filename_disk}}?width=1600" class="h-100">
              </div>
            </div>

            <div *ngIf="!gallery.vertical" class="d-flex justify-content-between info-box-bottom mt-3 align-items-end">
              <p class="font-weight-bolder text-uppercase vertical-text-bottom">
                {{gallery.month}}<br/><label class="font-weight-normal mb-0">{{gallery.year}}</label>
              </p>
              <p class="font-weight-bolder text-uppercase horizontal-text text-right">
                {{pic.directus_files_id.title}}<br><label class="font-weight-normal mb-0">{{gallery.name}}</label>
              </p>
            </div>
          </div>
        </div>
      </div>

    </ng-template>
  </swiper>
</div>

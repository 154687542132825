import {Injectable} from "@angular/core";
import {Gallery} from "./picture";
import {from, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ApiService} from "../common/api.service";

const GALLERY_FIELDS: string[] = ['slug', 'name', 'year', 'month', 'vertical', 'sort', 'pictures.*.*', 'pictures.*.*', 'category'];

@Injectable({providedIn: 'root'})
export class GalleryService {

  constructor(private readonly apiService: ApiService) {
  }

  public getGalleries(): Observable<Gallery[]> {
    // @ts-ignore
    return from(this.apiService.api.items('galleries').readByQuery({fields: GALLERY_FIELDS})).pipe(
      map(items => items.data)
    );
  }

  public getGalleryBySlug(slug: string): Observable<Gallery | null> {
    return from(this.apiService.api.items('galleries').readByQuery({
      // @ts-ignore
      fields: GALLERY_FIELDS,
      filter: {
        'slug': {
          "_eq": slug
        }
      }
    })).pipe(
      map(items => items.data.length === 1 ? items.data[0] : null)
    );
  }


}

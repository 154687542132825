  <div class="container content" *ngIf="news | async as news">

  <h1 class="page-title text-uppercase text-center mt-5 mb-6">News</h1>

  <div class="h-100 position-relative">
    <div class="vertical-divider d-none d-lg-inline-block"></div>

    <div class="row  mb-5" *ngFor="let newsEntry of news; odd as odd">
      <div class="col-lg-6 px-lg-5" [class.offset-lg-6]="odd" [class.text-lg-right]="!odd">
        {{newsEntry.date | date: 'dd.MM.YYYY'}}
        <h1 class="font-weight-bolder">{{newsEntry.title}}</h1>
        <p class="font-weight-lighter" [innerHTML]="newsEntry.description"></p>
        <p>
          <a *ngIf="newsEntry.internalLink" href="#" [routerLink]="newsEntry.internalLink">Mehr erfahren</a>
        </p>
        <p>
          <a *ngIf="newsEntry.externalLink" [href]="newsEntry.externalLink" target="_blank">Mehr erfahren</a>
        </p>
        <div class="card" *ngIf="newsEntry.image">
          <div class="card-body">
            <img src="https://backend.mirkoreichlin.ch/assets/{{newsEntry.image.filename_disk}}?width=1080" class="w-100">
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
